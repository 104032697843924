import { BuildingOfficeIcon, CurrencyDollarIcon, HomeIcon } from '@heroicons/react/24/outline'
import '@typeform/embed/build/css/popup.css'
import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { SdsNavbar } from 'sds-projects'
import 'tailwindcss/tailwind.css'
import { HomePage } from './HomePage'
import { PricingPage } from './PricingPage'
import { WhoMadeThisPage } from './WhoMadeThisPage'

const navigation = [
  { name: 'Home', page: '/', icon: HomeIcon },
  { name: 'Pricing', page: '/pricing', icon: CurrencyDollarIcon },
  {
    name: 'Who made this?',
    page: '/who-made-this',
    icon: BuildingOfficeIcon,
  },
]

const navbarTheme = {
  darkLogo: true,
  colors: {
    background: 'bg-black/80',
    border: 'border-neutral-900',
    tabs: 'text-neutral-200 hover:bg-neutral-400/20',
    hover: 'hover:bg-neutral-400/20',
    dropdown: 'bg-neutral-900 text-neutral-200 font-normal ring-neutral-400 hover:bg-neutral-400/20',
    dropdownItem: 'bg-neutral-900 divide-neutral-400 ring-neutral-400 border border-neutral-400',
    dropdownText: 'text-neutral-200 hover:bg-neutral-400/20',
  },
}

const App: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: '/pricing',
      element: <PricingPage />,
    },
    {
      path: '/who-made-this',
      element: <WhoMadeThisPage />,
    },
  ])

  return (
    <div className="bg-black h-full bg-cover bg-no-repeat bg-hero">
      <SdsNavbar projectName="Snap Ingredients" customTheme={navbarTheme} navigation={navigation} hideUserMenu>
        <div className="h-[calc(100vh_-_64px)] w-full overflow-auto">
          <RouterProvider router={router} />
        </div>
      </SdsNavbar>
    </div>
  )
}

export default App
