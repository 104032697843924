import { PricingPage as SDSPricingPage } from 'sds-projects'
import { Footer } from './components/Footer'

const features = ['Unlimited ingredients', 'Unlimited recipies', 'Unlimited meal plans']

export function PricingPage() {
  return (
    <div className="m-auto text-center text-gray-300 pb-10">
      <SDSPricingPage
        customTheme={{
          darkLogo: true,
          colors: {
            text: 'text-white',
            primaryButton: 'text-white bg-blue-600 border border-blue-600 hover:opacity-80 hover:bg-black/20',
            secondaryButton: 'cursor-not-allowed text-blue-600/90 border border-blue-600/90',
            icon: 'text-blue-500',
            primaryCard: 'bg-black/80 border border-blue-600',
            secondaryCard: 'bg-black/60 border border-blue-600',
            badge: 'text-white bg-blue-600/20',
          },
        }}
        freeTierFeatures={features}
      />
      <Footer />
    </div>
  )
}
