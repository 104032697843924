import React from 'react';

import {Instructions} from '../App';

interface RecipeTimelineProps {
  instructions: Instructions[];
}

const RecipeTimeline: React.FC<RecipeTimelineProps> = ({ instructions }) => (
    <div className="font-robot">
        <h3 className="mb-4 text-xl font-semibold text-gray-900">Instructions</h3>
        <ol className="text-gray-500 relative border-l border-gray-200">                  
            {instructions[0].steps.map((step) => (
                <li className="mb-10 ml-4">
                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white"></div>
                    <time className="mb-1 text-sm font-normal leading-none text-gray-400">Step {step.number}</time>
                    <p className="text-base font-normal text-gray-500">{step.step}</p>
                </li>
            ))}
        </ol>
    </div>
)

export default RecipeTimeline;