export function Footer() {
  return (
    <footer className="text-center rounded-lg text-white bg-black/70 max-w-xs m-auto px-2 py-4 mb-2 sm:mb-6">
      Made with ❤️ at{' '}
      <a
        href="https://ae.studio?utm_source=snapingredients.com"
        target="_blank"
        rel="noreferrer"
        className="hover:underline"
      >
        AE Studio
      </a>
    </footer>
  )
}
