import { ArrowDownIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import React, { useState } from 'react'
import Modal from 'react-modal'
import { twMerge } from 'tailwind-merge'
import 'tailwindcss/tailwind.css'
import { Footer } from './components/Footer'
import Gallery from './components/Gallery'
import RecipeTimeline from './components/RecipeTimeline'

interface Ingredient {
  id: number
  amount: number
  unit: string
  name: string
  image: string
}

export interface Instructions {
  steps: {
    number: number
    step: string
    ingredients: Ingredient[]
  }[]
}

export interface Recipe {
  id: string
  title: string
  image: string
  analyzedInstructions: Instructions[]
  readyInMinutes: number
  vegetarian: boolean
  vegan: boolean
  glutenFree: boolean
  dairyFree: boolean
  servings: number
  extendedIngredients: Ingredient[]
  usedIngredients: Ingredient[]
  nutrition: {
    nutrients: {
      name: string
      amount: number
      unit: string
    }[]
  }
}
export function HomePage() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [recipes, setRecipes] = useState<Recipe[]>([])
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [noRecipesFound, setNoRecipesFound] = useState<boolean>(false)

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
  }

  const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1)

  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const onFileUpload = async () => {
    if (selectedFile) {
      setLoading(true)
      setNoRecipesFound(false)
      const base64Image = await convertBase64(selectedFile)

      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/v1/recipes/generate',
        {
          image: base64Image,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': process.env.REACT_APP_BACKEND_API_KEY,
          },
        },
      )

      setRecipes(response.data)

      if (response.data.length == 0) {
        setNoRecipesFound(true)
      }

      setLoading(false)
    }
  }

  const getCaloriesFromRecipe = (recipe: Recipe) => {
    const calories = recipe.nutrition.nutrients.find((nutrient) => nutrient.name === 'Calories')
    return calories?.amount
  }

  const getIngredientsFromRecipe = (recipe: Recipe) => {
    const ingredients = recipe.extendedIngredients.map((ingredient) =>
      capitalizeFirstLetter(`${ingredient.name} (${ingredient.amount} ${ingredient.unit})`),
    )
    return ingredients.join(', ')
  }

  const handleSelectedRecipe = async (recipe: Recipe) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/recipes/${recipe.id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': process.env.REACT_APP_BACKEND_API_KEY,
      },
    })

    setSelectedRecipe(response.data)
  }

  return (
    <div className="App font-roboto">
      {recipes.length == 0 && (
        <section className="">
          <div className="py-6 px-4 mx-auto max-w-screen-xl text-center lg:px-0 lg:py-16 relative">
            <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-100 md:text-5xl lg:text-6xl">
              Your own Personal Chef
            </h1>
            <p className="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">
              Capture ingredients with a click, and we'll create customized recipes, complete with calorie counts and
              step-by-step cooking instructions.
            </p>

            <div className="mb-6 bg-black/70 p-8">
              <p className="text-white text-2xl mb-4">Try now!</p>
              <div className="flex flex-col justify-center items-center gap-8 sm:flex-row">
                <div>
                  <p className="text-white mb-2">1. Snap the ingredients</p>
                  <img className="rounded-md h-60" src="/images/vegetables.jpeg" />
                </div>
                <ArrowRightIcon className="hidden sm:block h-10 text-white" />
                <ArrowDownIcon className="sm:hidden h-10 text-white" />
                <div>
                  <p className="text-white mb-2">2. Upload it and get recipes</p>
                  <img className="rounded-md h-60" src="/images/results.jpeg" />
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-center w-full">
              <div className="p-4 md:basis-1/2 flex flex-col items-center justify-center w-full">
                <label
                  htmlFor="dropzone-file"
                  className="px-12 flex flex-col items-center justify-center w-full h-80 border-2 border-blue-600 border-dashed rounded-lg cursor-pointer bg-white/10 hover:bg-white/30"
                >
                  {!selectedFile && (
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        className="w-8 h-8 mb-4 text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-2 text-sm text-white">
                        <span className="font-semibold">Click to upload</span>
                      </p>
                      <p className="text-xs text-white">SVG, PNG or JPG (MAX. 800x400px)</p>
                    </div>
                  )}
                  <input onChange={onFileChange} id="dropzone-file" type="file" className="hidden" />
                  {selectedFile && <img className="h-80" src={URL.createObjectURL(selectedFile)} />}
                </label>
                {selectedFile && <p className="p-3 text-xs text-gray-200">{selectedFile.name}</p>}
                <div className="flex flex-col justify-center p-4">
                  <button
                    onClick={onFileUpload}
                    disabled={!selectedFile}
                    className={twMerge(
                      'p-4 text-white font-normal py-2 px-4 rounded-lg',
                      !selectedFile ? 'bg-gray-300/90' : 'bg-blue-500 hover:bg-blue-700',
                    )}
                  >
                    Generate Recipes
                  </button>

                  {noRecipesFound && (
                    <p className="p-3 text-xs text-gray-200">
                      {noRecipesFound && 'No recipes found. Please take a better picture.'}
                    </p>
                  )}

                  {loading && (
                    <div className="flex pt-2 justify-center" role="status">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 mr-2 text-gray-200 animate-spin  fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {recipes.length > 0 && (
        <>
          <section className="bg-gray-100 bg-opacity-10">
            <div className="flex flex-wrap justify-center p-4 pt-8 ">
              <button
                onClick={() => setRecipes([])}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Create new Recipe!
              </button>
            </div>
            <div className="flex flex-wrap justify-center p-4 ">
              {recipes.length > 0 && <Gallery recipes={recipes} handleOnClick={handleSelectedRecipe} />}
            </div>
          </section>

          <section>
            <Modal isOpen={!!selectedRecipe} onRequestClose={() => setSelectedRecipe(null)}>
              {selectedRecipe && (
                <div className="flex flex-row">
                  <div className="flex flex-col font-roboto">
                    <h2 className="text-4xl font-normal text-gray-900 md:text-5xl lg:text-6xl mb-1">
                      {selectedRecipe.title}
                    </h2>
                    <div className="flex flex-row">
                      <div className="flex flex-col h-full w-full">
                        <div className="flex flex-col md:flex-row my-4">
                          <div className="flex flex-row col-span-1 w-fit">
                            {selectedRecipe.nutrition.nutrients[0] && (
                              <div className="truncate pr-2 text-center items-center rounded-lg w-fit font-normal mb-2 text-xs">
                                <p className="bg-green-500 text-white font-normal py-2 px-2 rounded-lg">
                                  {getCaloriesFromRecipe(selectedRecipe)} Cal
                                </p>
                              </div>
                            )}
                            {selectedRecipe.readyInMinutes && (
                              <div className="truncate pr-2 text-center items-center rounded-lg w-fit font-normal mb-2 text-xs">
                                <p className="bg-green-500 text-white font-normal py-2 px-2 rounded-lg">
                                  {selectedRecipe.readyInMinutes} min
                                </p>
                              </div>
                            )}
                            {selectedRecipe.servings && (
                              <div className="truncate pr-2 text-center items-center rounded-lg w-fit font-normal mb-2 text-xs">
                                <p className="bg-green-500 text-white font-normal py-2 px-2 rounded-lg">
                                  {selectedRecipe.servings} servings
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="flex flex-row col-span-1 w-fit">
                            {selectedRecipe.vegan && (
                              <div className="truncate pr-2 text-center items-center rounded-lg w-fit font-normal mb-2 text-xs">
                                <p className="bg-green-500 text-white font-normal py-2 px-2 rounded-lg">Vegan</p>
                              </div>
                            )}
                            {selectedRecipe.vegetarian && (
                              <div className="truncate pr-2 text-center items-center rounded-lg w-fit font-normal mb-2 text-xs">
                                <p className="bg-green-500 text-white font-normal py-2 px-2 rounded-lg">Vegetarian</p>
                              </div>
                            )}
                          </div>
                          <div className="flex flex-row col-span-1 w-fit">
                            {selectedRecipe.glutenFree && (
                              <div className="truncate pr-2 text-center items-center rounded-lg w-fit font-normal mb-2 text-xs">
                                <p className="bg-green-500 text-white font-normal py-2 px-2 rounded-lg">Gluten Free</p>
                              </div>
                            )}
                            {selectedRecipe.dairyFree && (
                              <div className="truncate pr-2 text-center items-center rounded-lg w-fit font-normal mb-2 text-xs">
                                <p className="bg-green-500 text-white font-normal py-2 px-2 rounded-lg">Dairy Free</p>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="flex flex-col md:flex-row">
                          <div className="md:w-1/2">
                            <img className="rounded-lg" src={selectedRecipe.image} alt={selectedRecipe.title} />
                          </div>
                          <div className="flex md:w-2/3 py-4 md:py-0">
                            <div className="flex flex-col">
                              <div className="md:pl-4 pb-4">
                                <h3 className="mb-4 text-xl font-semibold text-gray-900">Ingredients</h3>
                                <p className="text-base font-normal text-gray-500">
                                  {getIngredientsFromRecipe(selectedRecipe)}
                                </p>
                              </div>
                              <div className="md:pl-4">
                                {selectedRecipe.analyzedInstructions.length > 0 && (
                                  <RecipeTimeline instructions={selectedRecipe.analyzedInstructions} />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Modal>
          </section>
        </>
      )}

      <Footer />
    </div>
  )
}
