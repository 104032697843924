import React from 'react';
import { type Recipe } from '../App';

interface CardProps {
    recipes: Array<Recipe>;
    handleOnClick: (recipe: Recipe) => void;
}


const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1)

const Gallery: React.FC<CardProps> = ({ recipes, handleOnClick }) =>{
    const getIngredientsFromRecipe = (recipe: Recipe) => {
        const ingredients = recipe.usedIngredients.map((ingredient) => capitalizeFirstLetter(`${ingredient.name} (${ingredient.amount} ${ingredient.unit})`))
        return ingredients.join(', ')
    }

    return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 font-roboto">
        {recipes.map((recipe) => (
            <div className="rounded-lg bg-gray-50 border-gray-200 shadow">
                <img title={recipe.title} alt={recipe.title} className="object-fill w-96 h-auto max-w-full rounded-t-lg hover:opacity-90 cursor-pointer" src={recipe.image} onClick={() => handleOnClick(recipe)} />
                <h5 className="truncate p-4 pb-1ext-left mb-2 text-sm md:text-2xl font-bold tracking-tight md:w-80 text-gray-900">{recipe.title}</h5>
                <div className="truncate text-left text-sm md:text-md font-light text-gray-500 md:w-80 px-4 pb-4">Ingredients: {getIngredientsFromRecipe(recipe)}</div>
            </div>
        ))}
    </div>)
} ;

export default Gallery;